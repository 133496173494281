body {
  margin: 0;
  padding: 0;
  background-color: white;
}

h4 {
  color: red;
  font-family: "Roboto", sans-serif;
}

:root {
  --gray-150: #3d4467;
}

.gray-50 {
  color: #c3c5ce;
}

.gray-90 {
  color: #5e6379;
}

.gray-100 {
  color: #545b79;
}

.default-color {
  color: #545b79;
}

.bg-black-100 {
  background-color: #eeeded;
  border: 0;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

/* Button hover effect */
#back-to-top-btn:hover {
  background-color: #6b8b65; /* Change background color on hover */
}

.transparent-modal-bundle .modal-content {
  border: none;
}

.transparent-modal-bundle .modal-body {
  background-color: unset;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: 80% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.transparent-modal-bundle .modal-header,
.transparent-modal-bundle .modal-body,
.transparent-modal-bundle .modal-footer {
  background-color: unset;
}

.transparent-modal .modal-content {
  background-color: unset;
  border: none;
}

.transparent-modal .modal-body {
  background-color: unset;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: 80% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.transparent-modal .modal-header,
.transparent-modal .modal-body,
.transparent-modal .modal-footer {
  background-color: unset;
}

.title {
  width: fit-content;
  max-width: 710px;
  margin: 0 0 30px;
  font-size: 48px;
  color: white;
}
.w-min-content {
  width: min-content;
}

/* navbar start */
.navbar {
}

.navbar .custom-toggler {
  border-color: transparent; /* Change border color to white */
}

.navbar .custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
/* navbar end */

/* start home */

.home {
  padding: 0px;
}

.promo {
  padding: 0px;
}

.section-2 {
  z-index: 2;
  background-image: url("./images/mobile/bg-tr.png");
  background-position: center;
  background-repeat: repeat-y;
  background-size: 100%;
  margin-top: -3rem;
  padding-bottom: 2rem;
}

.section-2-promo {
  z-index: 2;
  background-image: url("./images/mobile/bg-red.png");
  background-position: center;
  background-repeat: repeat-y;
  background-size: 100%;
  margin-top: -3rem;
  padding-bottom: 2rem;
}

.section-3 {
  margin-top: 3rem;
  padding: 1rem;
  background-color: #f2c731;
}

.btn-hero-wa {
  width: 90%;
  border-radius: 30px;
  background: linear-gradient(
      93.92deg,
      #f9bc2e 0%,
      #e29a1e 13.29%,
      #ffcd38 30.34%,
      #f0af30 73.17%,
      #e29a1e 83.8%,
      #ffcd38 96.43%,
      #dd931a 100.4%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  border: 1px solid rgba(1, 80, 164, 1);
}

.btn-hero-wa-promo {
  width: 90%;
  border-radius: 30px;
  background: linear-gradient(
      93.92deg,
      #f9bc2e 0%,
      #e29a1e 13.29%,
      #ffcd38 30.34%,
      #f0af30 73.17%,
      #e29a1e 83.8%,
      #ffcd38 96.43%,
      #dd931a 100.4%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  border: 1px solid rgba(1, 80, 164, 1);
}

.btn-hero-wa-box-promo {
  width: 100%;
  border-radius: 30px;
  background: linear-gradient(
      93.92deg,
      #f9bc2e 0%,
      #e29a1e 13.29%,
      #ffcd38 30.34%,
      #f0af30 73.17%,
      #e29a1e 83.8%,
      #ffcd38 96.43%,
      #dd931a 100.4%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  border: 1px solid rgba(1, 80, 164, 1);
}

.bt-download {
  background-color: #f2c730;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.box-fasilitas {
  margin-top: -2rem;
  text-align: left;
  padding: 1.5rem;
  border-radius: 5px;
  background-color: rgba(19, 21, 143, 0.5);
  margin-inline: 1rem;
}

.box-fasilitas-promo {
  margin-top: -2rem;
  text-align: left;
  padding: 1.5rem;
  border-radius: 5px;
  background-color: rgba(189, 101, 72, 0.558);
  margin-inline: 1rem;
}

.number-circle {
  background-color: white;
  border-radius: 100%;
  color: #f97619;
  font-weight: bold;
  text-align: center;
  padding-left: 10px;
  height: fit-content;
}

.btn-goto-wa {
  position: fixed;
  z-index: 1;
  bottom: 0;
  background-color: transparent;
  border: 0px;
  right: 0;
  background-image: url("./images/mobile/wa-btn.png");
  background-size: 100% 100%;
  width: 40px;
  height: 40px;
  margin-bottom: 2rem;
  margin-right: 2rem;
  align-content: end;
}
/* end home */

/* start not found */

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  font-family: "Arial", sans-serif;
}

.not-found-title {
  font-size: 10rem;
  margin: 0;
  color: #24b0a2;
}

.not-found-text {
  font-size: 1.5rem;
  color: #2d3436;
  margin: 1rem 0;
}

.not-found-button {
  padding: 0.75rem 1.5rem;
  color: #fff;
  background-color: #24b0a2;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.not-found-button:hover {
  background-color: #24b0a2;
}

.not-found-animation {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 200px;
  margin-top: 2rem;
}

.not-found-cloud {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: moveClouds 15s linear infinite;
}

.hot-promo {
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  height: 50px;
  background-color: #fc4130;
  color: white;
}

.not-found-cloud:nth-child(2) {
  width: 100px;
  height: 100px;
  top: 50px;
  left: 50%;
  animation-duration: 18s;
}
.guest-star-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

@keyframes moveClouds {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(200%);
  }
}

/* end not found */

/* Start media query */

/* Small */
@media (max-width: 768px) {
}

/* medium */

@media (min-width: 768px) {
}

@media (max-width: 992px) {
}

/* Large */

@media (min-width: 1198px) {
  .home {
    padding-inline: 30rem;
  }
  .promo {
    padding-inline: 30rem;
  }
}

/* End media query */
